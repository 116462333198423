<template>
    <div class="payment-page">
        <div class="main-h1">Мой календарь</div>
        <div class="card">
            <div class="card-body">
                <div class="card-body-title" v-if="$store.state.isAdmin()">
                    <select v-model="city_id">
                        <option value="0">Все</option>
                        <option v-for="option in cities" :value="option.id">{{ option.title }}</option>
                    </select>
                </div>
                <Calendar :events="calendarEvents" />
                <!--
                <div class="card" v-if="claims.length">
                    <div class="card-body">
                        <div class="card-body-title">Список дел</div>
                        <claim-list :list="claims"/>
                    </div>
                </div>
                -->
                <!--
                <div class="card" v-if="appeals.length">
                    <div class="card-body">
                        <div class="card-body-title">Список обращений</div>
                        <appeal-list :list="appeals" v-if="appeals.length"/>
                    </div>
                </div>
                <div class="card" v-if="payments.length">
                    <payment-list :list="payments" @updated="getPayments"/>
                </div>
                -->
            </div>
        </div>
    </div>
</template>

<script>
//import paymentList from '../../../components/payment/list';
import claimList from '../../../components/claim/list.vue';
import appealList from '../../../components/appeal/list.vue';
import Calendar from '../../../components/calendar/index';
export default {
    name: "paymentPage",
    components:{claimList,appealList,Calendar},
    data() {
        return {
            city_id:0,
            cities: [],
            //payments:[],
            claims:[],
            //appeals:[],
            calendarEvents: [],
            type: ['claim', 'payment', 'notification', 'appeal'],
        }
    },
    methods: {
        calendarFill() {
            this.calendarEvents = [];
            this.claims.forEach(item => {
                this.calendarEvents.push({ id: item.id, type: 'claim', title: item.title, date: this.$moment(item.stage_dl)});
            })
            /*
            this.appeals.forEach(item => {
                this.calendarEvents.push({ id: item.id, type: 'appeal', title: item.title, date: this.$moment(item.stage_dl)});
            })
            this.payments.filter(item => item.status_id != 3).forEach(item => {
                this.calendarEvents.push({ id: item.id, type: 'payment', title: item.title, date: this.$moment(item.status_dl)});
            })
            */
        },
        getPayments() {
            this.$api.get(`payment/my`).then(res => {
                if (res.data.success) {
                    this.payments = res.data.data
                    this.calendarFill();
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getClaims() {
            this.$api.get(`claim/my`).then(res => {
                if (res.data.success) {
                    if (this.city_id == 0) {
                        this.claims = res.data.data
                    } else {
                        this.claims = res.data.data.filter(item => {return item.city_id == this.city_id})
                    }
                    this.calendarFill();
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getCities() {
            this.$api.get(`city`).then(res => {
                if (res.data.success) {
                    this.cities = res.data.data
                }
            })
        },
        getAppeals() {
            this.$api.get(`appeal/my`).then(res => {
                if (res.data.success) {
                    this.appeals = res.data.data
                    this.calendarFill();
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
    },
    watch: {
        'city_id': function (){
            this.getClaims();
        }
    },
    created() {
        this.getClaims();
        this.getCities();
        //this.getPayments();
        //this.getAppeals();
    }
}
</script>

<style scoped>

</style>